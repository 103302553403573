<template>
  <v-row align="center" justify="center">
    <v-col :class="alignClass" cols="12">
      <v-col class="darkGrey--text">
        <v-icon></v-icon>
        <inline-svg
          :max-height="fullPageError ? 400 : 65"
          :src="
            icon || image
              ? `/media/svg/${image}.svg`
              : `/media/placeholders/all-done${
                  $vuetify.theme.dark ? '-dark' : ''
                }.svg`
          "
          class="mt-6"
        />
        <p
          class="mt-4 bold-text darkGrey--text text-center"
          v-html="firstText || 'No data'"
        ></p>
        <p
          class="mt-4 mx-auto mb-5 bodyFont"
          style="max-width: 70ch"
          v-html="secondText"
        ></p>
        <p class="mt-4 mb-5 bodyFont font-weight-normal text-center">
          <slot name="extraMessage"></slot>
        </p>
      </v-col>
    </v-col>
    <v-col class="pt-0" v-if="withButton" cols="12">
      <div class="d-flex align-center justify-center">
        <v-btn
          :outlined="$vuetify.theme.dark"
          color="primary"
          @click="onActionClick"
        >
          <v-icon left small>{{ btnIcon }}</v-icon>
          {{ btnText || "Go back" }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    firstText: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      default: null,
    },
    btnIcon: {
      type: String,
      default: "mdi-arrow-left",
    },
    secondText: {
      type: String,
      default: null,
    },
    alignClass: {
      type: String,
      default: "text-center",
    },
    fullPageError: {
      type: String,
      default: null,
    },
    withButton: {
      type: Boolean,
      default: false,
    },
    permissionIssues: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onActionClick() {
      if (!this.btnText) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$emit("btnClicked");
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
