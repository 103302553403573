var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    class: _vm.alignClass,
    attrs: {
      "cols": "12"
    }
  }, [_c('v-col', {
    staticClass: "darkGrey--text"
  }, [_c('v-icon'), _c('inline-svg', {
    staticClass: "mt-6",
    attrs: {
      "max-height": _vm.fullPageError ? 400 : 65,
      "src": _vm.icon || _vm.image ? "/media/svg/".concat(_vm.image, ".svg") : "/media/placeholders/all-done".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-4 bold-text darkGrey--text text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.firstText || 'No data')
    }
  }), _c('p', {
    staticClass: "mt-4 mx-auto mb-5 bodyFont",
    staticStyle: {
      "max-width": "70ch"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.secondText)
    }
  }), _c('p', {
    staticClass: "mt-4 mb-5 bodyFont font-weight-normal text-center"
  }, [_vm._t("extraMessage")], 2)], 1)], 1), _vm.withButton ? _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center"
  }, [_c('v-btn', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": _vm.onActionClick
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.btnIcon))]), _vm._v(" " + _vm._s(_vm.btnText || "Go back") + " ")], 1)], 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }